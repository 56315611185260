@import "../../../data/styles.css";

.works-body {
	margin-bottom: 10px;
}

.work {
	display: flex;
	flex-direction: column;
	padding-bottom: 40px;
	width: 100%;
	height: auto;
}

.work-main{
	display:flex;
	position: relative;
	flex-direction: column;
	top: -5%;
}

.work-header{
	display:flex;
}

.work-card {
	display:flex;
	flex-direction: column;
	height: 10%;
	width: 100%; 
	position: relative;
}


.work-image {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	outline: 6px solid white;
	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
}

.work-title {
	font-size: 15px;
	font-weight: 700;
	padding-left: 20px;
	margin-top: -3px;
	color: var(--secondary-color);
}

.work-subtitle {
	position: relative;
	font-size: 13px;
	color: var(--secondary-color);
	padding-top: 22px;
	padding-left: 50px;
}

.work-duration {
	width: 100%;
	font-size: 13px;
	padding-top: 20px;
	text-align: right;
	color: var(--tertiary-color);
}

.work-text {
	position: relative;
	font-size: 13px;
	color: var(--secondary-color);
	padding-left: 30px;
}

